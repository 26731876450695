<template>
    <div class="has_fixed_bottom_box">
        <div class="container">
            <div class="mx-4">
                <h4 class="mt-4" :v-html="$t('unsubscribe.2')">
                </h4>
                <ul class="small my-4">
                    <li class="d-flex lh-sm mb-1"><small>ㆍ</small><small class="text-body text-opacity-50">{{$t('unsubscribe.3')}}</small></li>
                    <li class="d-flex lh-sm mb-1"><small>ㆍ</small><small class="text-body text-opacity-50">{{$t('unsubscribe.4')}}</small></li>
                    <li class="d-flex lh-sm mb-1"><small>ㆍ</small><small class="text-danger">{{$t('unsubscribe.5')}}</small></li>
                </ul>
            
                <div class="py-2 round_checkbox" v-for="(item,index) in [ $t('unsubscribe.6'), $t('unsubscribe.7')]" :key="index">
                    <input type="radio" v-model="reason" :value="item" :id="'reason'+index">
                    <label :for="'reason'+index">
                        <div class="chk_icon me-2"></div>
                        <small class="conditions text-truncate">{{item}}</small>
                    </label>
                </div>
                <div class="py-2 round_checkbox">
                    <input type="radio" v-model="reason" value="other" id="reason_other">
                    <label for="reason_other">
                        <div class="chk_icon me-2"></div>
                        <small class="conditions text-truncate">{{$t('unsubscribe.8')}}</small>
                    </label>
                </div>
                <textarea class="w-100 border bg-body small rounded-2 p-2 border-gray-relative-300" rows="3" v-model="reson_other" :disabled="reason != 'other'"></textarea>
            </div>
            
            <div class="mx-4">
                <div class="form-group mt-3">
                    <label class="small text-gray-relative-600">{{$t('unsubscribe.9')}}</label>
                    <input class="form-control thick bg-body border-gray-relative-300" type="password" required placeholder="password" v-model="pw" autocomplete="none">
                </div>
                <!-- <div class="ml-2 mt-2 d-inline-block">
                    <span class="small text-gray-relative-600 pr-2">{{$t('unsubscribe.10')}}</span>
                    <router-link to="/find-pw" class="small text-muted underline">{{$t('unsubscribe.11')}}</router-link>
                </div> -->
            </div>
            <div class="fixed_bottom_box p-3">
                <button class="btn btn-block btn-primary w-100" :disabled="Chk()" @click="OptOut()">{{$t('unsubscribe.13')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            title: this.$t("unsubscribe.1"),
            reason: '',
            reson_other: '',
            pw: '',
        }
    },
    methods:{
        Chk(){
            let disabled = false;

            if( this.reason != '' ){
                if( this.reason == 'other' && this.reson_other == '' ){
                    disabled = true
                }
            }else{
                disabled = true;
            }

            return disabled;
        },
        OptOut(){
            this.$confirm(this.$t('unsubscribe.14')).then(( result )=>{
                if( result ){

                    const password = this.pw;

                    const api = this.$store.state.baseURL;

                    this.$http.post(`${api}/member/common/DeleteMember`, {password}).then(
                    res => {
                        if (res.status == 200) {
                            const code = res.data.code;
                            if(code == '200'){    
                                this.$store.dispatch('SETLOGOUT').then(()=>{
                                    this.$alert(this.$t('unsubscribe.15')).then(()=>{
                                        this.$router.push('/');
                                    });
                                });
                                
                            }else{
                                this.$alert(this.$t("signinAlert.6"));
                            }
                        }
                    })
                    
                }
            }).catch(e)
        }
    }
}
</script>

<style>

</style>