<template>
    <div id="MyInfo" class="has_fixed_bottom_box">
        <div class="container">
            <div class="form_box">
                <div class="form-group line bold">
                    <div class="d-flex align-items-center">
                        <label>{{$t("myinfo.3")}}</label>
                        <div class="ml-2 social_badge" :class="social_type" v-if="social_type != ''">
                            <!-- 네이버, 구글, 카카오, 페이스북, 애플 -->
                            <div class="icon"><img :src="require(`@/assets/img/social/${social_type}.png`)" :alt="social_type"></div>
                            <span class="txt">로그인</span>
                        </div>
                    </div>
                    <input type="email" class="form-control main_txt" :value="info.email" disabled>
                </div>
            </div>

            
            <div class="form_box mt-5">
                <div class="form-group line bold">
                    <label>{{$t("menu.14")}}</label>
                </div>
            </div>
            <h6 class="mt-2 mb-3 flex justify-content-between tab_tit" @click="tab01 =! tab01">
                <span><i class="fas fa-qrcode"></i> BTC QR {{$t("kyc.33")}} </span>
                <i class="fas fa-chevron-down"></i>
            </h6>
            <div v-if="tab01">
                <div class="qrcode_box" v-if="btc_chk">
                    <vue-qrcode :value="btc_addr" style='width:100%'/>
                </div>
                <div class="form_box">
                    <div class="form-group line">
                        <div class="r_btn">
                            <input type="text" class="form-control border main_txt small" :value="btc_addr" disabled>
                            <button class="btn">
                                <i class="far fa-copy" @click="CopyWallet(btc_addr)"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <h6 class="mt-2 mb-3 flex justify-content-between tab_tit" @click="tab02 =! tab02">
                <span><i class="fas fa-qrcode"></i> ETH QR {{$t("kyc.33")}}</span>
                <i class="fas fa-chevron-down"></i>
            </h6>
            <div v-if="tab02">
                <div class="qrcode_box" v-if="eth_chk">
                    <vue-qrcode :value="eth_addr" style='width:100%'/>
                </div>
                <div class="form_box">
                    <div class="form-group line">
                        <div class="r_btn">
                            <input type="text" class="form-control border main_txt small" :value="eth_addr" disabled>
                            <button class="btn">
                                <i class="far fa-copy" @click="CopyWallet(eth_addr)"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <h6 class="mt-2 mb-3 flex justify-content-between tab_tit" @click="tab03 =! tab03">
                <span><i class="fas fa-qrcode"></i> BNB QR {{$t("kyc.33")}}</span>
                <i class="fas fa-chevron-down"></i>
            </h6>
            <div v-if="tab03">
                <div class="qrcode_box" v-if="bnb_chk">
                    <vue-qrcode :value="bnb_addr" style='width:100%'/>
                </div>
                <div class="form_box">
                    <div class="form-group line">
                        <div class="r_btn">
                            <input type="text" class="form-control border main_txt small" :value="bnb_addr" disabled>
                            <button class="btn">
                                <i class="far fa-copy" @click="CopyWallet(bnb_addr)"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div> -->

            <h6 class="mt-2 mb-3 flex justify-content-between tab_tit" @click="tab04 =! tab04">
                <span><i class="fas fa-qrcode"></i> AVAX QR {{$t("kyc.33")}}</span>
                <i class="fas fa-chevron-down"></i>
            </h6>
            <div v-if="tab04">
                <div class="qrcode_box" v-if="avax_chk">
                    <vue-qrcode :value="avax_addr" style='width:100%'/>
                </div>
                <div class="form_box">
                    <div class="form-group line">
                        <div class="r_btn">
                            <input type="text" class="form-control border main_txt small" :value="avax_addr" disabled>
                            <button class="btn">
                                <i class="far fa-copy" @click="CopyWallet(avax_addr)"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="small py-4 text-right">
                <router-link :to="`/${$i18n.locale}/page/unsubscribe`" class="text-muted">{{$t("unsubscribe.1")}}</router-link>
            </div>
            <!-- <div class="form_box mt-5">
                <div class="form-group line bold">
                    <label>내 지갑 관리하기</label>
                </div>
                <button type="button" class="btn btn-block btn-dark" @click="infoChk=true"><i class="far fa-save"></i> <small>니모닉코드 백업</small></button>
            </div>
            <div class="infoPp wtdPw" v-if="infoChk">
                <div class="dimm" @click="infoChkOff()"></div>
                <div class="content pt-4">
                    <i class="fas fa-times" @click="infoChk = false"></i>
                    <div class="circle">
                        <i class="fas fa-lock"></i>
                    </div>
                    <p class="text-center py-3">{{$t('signup.21')}}<br> {{$t('signup.22')}}</p>
                    <input type="password" pattern="[0-9]{8}" maxlength="8" inputmode="numeric" class="wtdPw form-control mx-auto mb-3 text-center" style="max-width:250px; letter-spacing: 10px;" ref="pin" placeholder="●●●●●●●●" v-model="pin" @focusout="PinCodeChaeck()" @keyup.enter="infoChk = false" required>
                    <button type="button" style="max-width:250px;" class="btn mx-auto btn-block btn-primary mb-4" @click="infoChk = false"><small>{{$t('signup.19')}}</small></button>
                </div>
            </div> -->
                
            <div class="fixed_bottom_box p-3">
                <button type="button" class="btn btn-block btn-primary" @click="logout"> <small>{{$t("myinfo.6")}}</small></button>
            </div>
        </div>
    </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'
export default {
    components: {
        VueQrcode,
    },
    data(){
        return{
            title: this.$t("menu.3"),
            prevP:true,
            pageD: false,
            info: {},
            btc_chk: false,
            btc_addr: '',
            eth_chk: false,
            eth_addr: '',
            bnb_chk: false,
            bnb_addr: '',
            avax_chk: false,
            avax_addr: '',

            tab01: false,
            tab02: false,
            tab03: false,
            tab04: false,

            kyc: '',
            kycStatus: '',
            kycChk: false,
            
            infoChk: false,
            pin: '',

            social_type: this.$store.state.social
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
        
        const login = this.$store.state.login;
        if(login){
            this.Token_Check();
        }
    },
    methods: {                
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                const code = res.data.code;
                if(code == '200'){    
                    this.myInfo();
                    this.CheckKYC();
                    this.WalletInfo();
                }else if(code == '9999'){
                    this.$alert(this.$t('signout.1')).then(()=>{
                        this.$store.dispatch('SETLOGOUT').then(()=>{
                            this.$router.push({name:'Signin'});
                        });
                    })
                }
                }
            })
        },
        logout() {            
            this.$confirm(this.$t("signout.2")).then(() => {                
                this.$store.dispatch('SETLOGOUT').then(()=>{
                    this.$router.push({name:'Signin'});
                });
            }).catch(() => {})
        },
        myInfo: function() {
            const api = this.$store.state.baseURL;
            
            this.$http.post(`${api}/member/setting/my_info`,{}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.info = res.data.info;
                        }
                    }
                }
            )
        },
        CheckKYC(){
            const api = this.$store.state.baseURL;
			this.$http.post(`${api}/member/wallet/kyc/check`,{}).then(
			res => {
				if(res.status==200){
                    
                    if(res.data.code == '100'){
                        this.kycChk = true;
                        const check = res.data.check;
                        this.kycStatus = check.status;
                        if(check.status == "Y") this.kyc = this.$t("kyc.28");
                        else if(check.status == "H") this.kyc = this.$t("kyc.31");
                        else this.kyc = this.$t("kyc.32");
                    }else {
                        this.kycChk = false;
                        this.kyc = this.$t("kyc.29");
                    }
				}
			}).finally(() => {
                this.save = false;
            })
        },
        WalletInfo() {
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/wallet_info_list`, {}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        const info = res.data.info;
                        this.WalletDInfo(info);
                    }
                }
            })
        },
        WalletDInfo(info) {
            for(const el of info) {
                if(el.symbol == "BTC") {
                    this.Btc_WalletDecoding(el.address);
                }else if(el.symbol == "ETH") {
                    this.Eth_WalletDecoding(el.address);
                }else if(el.symbol == "BNB") {
                    this.BNB_WalletDecoding(el.address);
                }else if(el.symbol == "AVAX"){
                    this.AVAX_WalletDecoding(el.address);
                }
            }
        },
        Btc_WalletDecoding(address) {
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/wallet_info/decoding`, {address}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                         this.btc_addr = res.data.address;
                    }
                }
            }).finally(() => {          
                this.btc_chk = true;
            })
        },
        Eth_WalletDecoding(address) {
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/wallet_info/decoding`, {address}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                         this.eth_addr = res.data.address;
                    }
                }
            }).finally(() => {          
                this.eth_chk = true;
            })
        },
        BNB_WalletDecoding(address) {
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/wallet_info/decoding`, {address}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                         this.bnb_addr = res.data.address;
                    }
                }
            }).finally(() => {          
                this.bnb_chk = true;
            })
        },
        AVAX_WalletDecoding(address) {
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/wallet_info/decoding`, {address}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                         this.avax_addr = res.data.address;
                    }
                }
            }).finally(() => {          
                this.avax_chk = true;
            })
        },
        CopyWallet: function (value) {
            this.$copyText(value).then( 
            () =>{
                this.$fire({title:this.$t('commision.4'), html : value});
            })
        },
        infoChkOff: function(){
            this.infoChk = false;
        },     
        PinCodeChaeck(){
            const pin = this.pin;
            if(this.pin  ==''){
                this.pinErr = true;
            }
            if(pin.length != 8){
                this.pinErr = true;
            }
            else{
                this.pinErr = false;
            }
        },
        PinCheckingData() {

        }
    },
}
</script>
<style scoped>
    .form-group.bold input{
        font-size: 17px;
        font-weight: 500;
    }
    .form-group.bold label{
        font-size: 18px;
        font-weight: bold;
        color: #222;
    }

    .form-group .small{
        font-size: 14px ;
    }


    /* social login badge */
    .social_badge{
        display: flex;
        border: 1px solid;
        font-size: 10px;
        line-height: 17px;
    }
    .social_badge .icon{
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .social_badge img{
        width: 16px;
    }
    .social_badge .txt{
        padding: 0 3px;
        line-height: 18px;
    }

    .social_badge.Naver{
        color: #00c73c;
        border-color: #00c73c;
    }
    .social_badge.Naver .icon{
        background-color: #00c73c;
    }
    .social_badge.Google{
        color: #333;
        border-color: #ddd;
    }
    .social_badge.Google .icon{
        background-color: #e9e9e9;
    }
    .social_badge.Kakao{
        color: #cbb315;
        border-color: #fee227;
    }
    .social_badge.Kakao .icon{
        background-color: #fee227;
    }
    .social_badge.Facebook{
        color: #46629d;
        border-color: #46629d;
    }
    .social_badge.Facebook .icon{
        background-color: #46629d;
    }
    .social_badge.Apple{
        color: #000;
        border-color: #000;
    }
    .social_badge.Apple .icon{
        background-color: #000;
    }
</style>