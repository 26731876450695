<template>
    <div id="ChgTransactionPw" class="has_fixed_bottom_box">
        <div class="container">
            <div class="step01" v-if="step == 1">
                <div class="top_txt_box mb-5 mt-4">
                    <h3><div class="">
                        {{$t('change.1')}}
                    </div></h3>
                    <p>
                        {{$t('change.2')}}<br>
                    </p>
                </div>
                <div class="form_box">
                    <div class="form-group line">
                        <label>{{$t('change.3')}}</label>
                        <input type="email" class="form-control" v-model="email" @keyup.enter="EmailCheck">
                    </div>
                </div>
                <div class="fixed_bottom_box p-3">
                    <button type="button" class="btn btn-block btn-primary " @click="EmailCheck"><small>{{$t('change.4')}}</small></button>
                </div>
            </div>
            <div class="step02" v-if="step == 2">
                <div class="top_txt_box mb-5 mt-4">
                    <h3><div class="">
                        {{$t('change.5')}}
                    </div></h3>
                    <p>
                        {{$t('change.6')}}<br>
                        {{$t('change.7')}}
                    </p>
                </div>
                <div class="form_box">
                    <div class="form-group line">
                        <label>{{$t('change.8')}}</label>
                        <input type="text" class="form-control" v-model="code" :placeholder="$t('change.8')" required>
                    </div>
                </div>
                <div class="fixed_bottom_box p-3">
                    <button type="button" class="btn btn-block btn-primary " @click="ConfirmEmailCode"><small>{{$t('change.9')}}</small></button>
                </div>
            </div>
            <div class="step02" v-if="step == 3">
                <div class="top_txt_box mb-5 mt-4">
                    <h3><div class="">
                        {{$t('change.28')}}
                    </div></h3>
                    <p>
                        {{$t('change.29')}}
                    </p>
                </div>
                <div class="form_box">
                    <div class="form-group line" v-if="!pin_set">
                        <label>{{$t('change.12')}}</label>
                        <input type="password" pattern="[0-9]*" maxlength="8" class="form-control" v-model="old_pin" :placeholder="$t('change.33')" required>
                    </div>
                    <div class="form-group line">
                        <label>{{$t('change.13')}}</label>
                        <input type="password" pattern="[0-9]*" maxlength="8" class="form-control" v-model="pin" :placeholder="$t('change.33')" required>
                    </div>
                    <div class="form-group line">
                        <label>{{$t('change.13')}} {{$t('change.14')}}</label>
                        <input type="password" pattern="[0-9]*" maxlength="8" class="form-control" v-model="pinConf" :placeholder="$t('change.33')" required>
                    </div>
                </div>
                <div class="fixed_bottom_box p-3">
                    <button type="button" class="btn btn-block btn-primary" @click="pinChange" v-if="!pin_set"><small>{{$t('change.15')}}</small></button>
                    <button type="button" class="btn btn-block btn-primary" @click="pinChangeFirst" v-else><small>{{$t('change.15')}}</small></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            title:  this.$t("menu.5"),
            prevP:true,
            pageD: false,
            email: "",
            step: 1,
            code: '',
            old_pin: '',
            pin: '',
            pinConf: '',
            social: this.$store.state.social,
            pin_set: false
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD);
        this.Token_Check();

        if(this.social != '') {
            this.Pin_First_check();
        }
    },
    methods: {    
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                    const code = res.data.code;
                    if(code == '9999'){
                        this.$alert(this.$t('signout.1')).then(()=>{
                            this.$store.dispatch('SETLOGOUT').then(()=>{
                                this.$router.push({name:'Signin'});
                            });
                        })
                    }
                }
            })
        },  
        Pin_First_check: function() {
            const api = this.$store.state.baseURL;
            
            this.$http.post(`${api}/member/common/pin/first/check`,{}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='100'){
                            this.step = 3;
                            this.pin_set = true;
                        } else {
                            this.pin_set = false;
                        }
                    }
                }
            )
        },
        EmailCheck: function() {
            const api = this.$store.state.baseURL;
            
            const email = this.email;
            if(email == ""){
                this.$alert(this.$t('change.16'));
                return;
            }

            var check = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(email);
            if(!check){
                this.$alert(this.$t('change.17'));
                return;
            }

            this.$http.post(`${api}/member/setting/passwordChg/email_check`,{email}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.EmailCodeSend();
                        }
                        else{
                            this.$alert(this.$t('change.18'));
                            return;
                        }
                    }
                }
            )
        },  
        EmailCodeSend: function() {
            const api = this.$store.state.baseURL;
            
            this.$http.post(`${api}/member/common/email_security`,{}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.step = 2;
                        }
                    }
                }
            )
        },
        ConfirmEmailCode:function(){
            const api = this.$store.state.baseURL;
            const code = this.code;

            if(code == ""){
                this.$alert(this.$t('change.19'));
                return false;
            }

            this.$http.post(`${api}/member/common/email_code_check`,{code}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.step = 3;
                        }
                        else {
                            this.$alert(this.$t('change.20'));
                            return;
                        }
                    }
                }
            )
        },
        pinChange: function() {             
            if(this.old_pin.length != 8){
                this.$alert(this.$t('change.30'))
                return;
            }

            if(this.pin.length != 8){
                this.$alert(this.$t('change.31'))
                return;
            }

            if(this.pin !== this.pinConf){
                this.$fire({html:this.$t('change.22')});
                return;
            }
            
            const api = this.$store.state.baseURL;
            const new_pin = this.pin;
            const old_pin = this.old_pin;
            
            this.$http.post(`${api}/member/setting/pin_change`,{old_pin,new_pin}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.$alert(this.$t('change.32')).then(()=>{
                                this.$router.push('/'+this.$i18n.locale+'/page/menu');
                            })
                        }
                        else if(res.data.code=='100'){
                            this.$alert(this.$t('change.25'));
                            this.oldPassword = "";
                        }
                        else if(res.data.code=='300'){
                            this.$alert(this.$t('change.26'));
                        }
                        else if(res.data.code=='400'){
                            this.$alert(this.$t('change.27'));
                        }
                    }
                }
            )

        },
        
        pinChangeFirst: function() {    
            if(this.pin.length != 8){
                this.$alert(this.$t('change.31'))
                return;
            }

            if(this.pin !== this.pinConf){
                this.$fire({html:this.$t('change.22')});
                return;
            }
            
            const api = this.$store.state.baseURL;
            const new_pin = this.pin;
            
            this.$http.post(`${api}/member/setting/pin_change/first`,{new_pin}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.$alert(this.$t('change.32')).then(()=>{
                                this.$router.push('/'+this.$i18n.locale+'/page/menu');
                            })
                        }
                        else if(res.data.code=='300'){
                            this.$alert(this.$t('change.26'));
                        }
                    }
                }
            )

        }
    },
}
</script>