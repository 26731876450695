<template>
    <div id="MyWallet" class="">
        <div class="">
            <vue-element-loading :active="save" spinner="bar-fade-scale"/>
            <div class="form_box">
                <div class="form-group mb-2 px-3">
                    <select class="form-control default mt-0" v-model="selected_coin">
                        <option :value="{no:'',net:'',symbol:''}" v-show="false">{{$t('mywallet.20')}}</option>
                        <option :value="{no: item.no, net: item.net, symbol: item.symbol}" v-for="(item,index) in coin_list" :key="index">{{item.symbol}}</option>
                    </select>
                    <input type="text" class="form-control mt-2" v-model="addr_title" :placeholder="$t('mywallet.21')">
                    <input type="text" class="form-control mt-2" v-model="address" :placeholder="$t('mywallet.22')">
                    <div class="d-flex">
                        <button type="button" class="btn btn-block btn-primary  mt-2 mr-2" style="width:80%" @click="isAddress" v-if="!edit"><small>{{$t('mywallet.4')}}</small></button>
                        <button type="button" class="btn btn-block btn-primary  mt-2 mr-2" style="width:80%" @click="isAddress" v-else><small>{{$t('mywallet.23')}}</small></button>
                        <button type="button" class="btn btn-block btn-primary  mt-2" style="width:20%" @click="runCamera()"><i class="fas fa-camera" style="font-size:1em;"></i></button>
                    </div>
                </div>
                <div class="payment-get px-3 pb-3" v-if="camera_area">
                    <button type="button" class="btn btn-block btn-danger mb-2" @click="CancelQr"><small>Cancel</small></button>
                    <qrcode-reader class="border border-secondary rounded" style="border-width:2px !important;" :camera="camera" @decode="onDecode"></qrcode-reader>
                </div>
            </div>
                
            <div class="botC">
                <p class="px-3 pt-3 mb-0 small main_txt pb-3">{{$t('mywallet.5')}}</p>
                <div class="container pt-0 history">
                    <div class="history_box">
                        <ul class="pl-0 addr_list">                            
                            <li v-for="(item,index) in addr_list" :key="index" class=" py-3 px-0">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="addr_name mb-1"> {{item.title}}</div>
                                    <span>
                                        <small class="removeBtn pr-2 mr-2" @click="editClick(item.no, item.coin_no, item.net, item.symbol, item.title, item.address)">{{$t('mywallet.23')}}</small>
                                        <small class="removeBtn" @click="AddressDel(item.no, item.title)">{{$t('mywallet.6')}}</small>
                                    </span>
                                </div>
                                <div class="flex align-items-center">
                                    <span class="badge badge-gray font-weight-normal mr-2">{{item.symbol}}</span>
                                    <div class="addr_txt main_txt" @click="CopyWallet(item.address)">{{item.address}}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
    components: {
        QrcodeStream
    },
    data(){
        return{
            save: this.false,
            title: this.$t("menu.18"),
            prevP:true,
            pageD: false,
            selected_coin: {no:'',net:'',symbol:''},
            addr_title: '',
            address: '',
            coin_list:[],
            addr_list:[],
            edit_no: '',
            edit: false,
            camera: 'auto',
            camera_area : false,
            error: '',
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
        
        const login = this.$store.state.login;
        if(login){
            this.Token_Check();
        }
    },
    methods:{   
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                const code = res.data.code;
                if(code == '200'){     
                    this.CoinInfo();
                    this.withdrawAddress();
                }else if(code == '9999'){
                    this.$alert(this.$t('signout.1')).then(()=>{
                        this.$store.dispatch('SETLOGOUT').then(()=>{
                            this.$router.push({name:'Signin'});
                        });
                    })
                }
                }
            })
        },
        CoinInfo() {
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/coin_info`, {}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        const info = res.data.info;
                        info.forEach(ele => {         
                            this.coin_list.push({no: ele.no, net: ele.net, symbol: ele.symbol});
                        });
                    }
                }
            })
        },
        withdrawAddress : function(){
            this.save = true;
			const api = this.$store.state.baseURL;
			this.$http.post(`${api}/member/wallet/transfer/address`,{}).then(
			res => {
				if(res.status==200){
					if(res.data.code=='200'){
                        this.addr_list = res.data.list;
					}
				}
			}).finally(() => {
                this.save = false;
            })
		},
        isAddress : function(){
            const net =this.selected_coin.net;
            const token = this.selected_coin.symbol;

            const title = this.addr_title;
            const address = this.address;
            
            if(token==""){
                this.$alert(this.$t('mywallet.24'));
                return false;
            }

            if(title==""){
				this.$alert(this.$t('mywallet.8'));
				return false;
            }
			
			if(address==""){
				this.$alert(this.$t('mywallet.9'));
				return false;
            }
            
            this.save = true;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/isAddress`,{net, address}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){                        
                        if(res.data.info ==true){
                            this.AddressCheck();
                        }						
                        else{
                            this.$alert(this.$t('mywallet.19'));
                        }
                    }else{
                        this.$alert(this.$t('mywallet.10'));
                    }
                }
            }).finally(() => {
                this.save = false;
            })
        },
        AddressCheck() {
			const address = this.address;

            this.save = true;
            const api = this.$store.state.baseURL;
			this.$http.post(`${api}/member/wallet/transfer/address/check`,{address}).then(
			res => {
				if(res.status==200){
                    if(res.data.code == '200'){
                        const chk = res.data.chk;
                        
                        if(!this.edit){
                            if(chk > 0){
                                this.$confirm(this.$t('mywallet.25')).then(() => {                                    
                                    this.AddressSave();
                                }).catch(() => {
                                    this.address = "";
                                })
                            }
                            else{
                                this.AddressSave();
                            }
                        }
                        else {
                            this.AddressEdit();
                        }                        
                    }				
				}
			}).finally(() => {
                this.save = false;
            })
        },
        AddressSave : function(){
            const api = this.$store.state.baseURL;
            const token = this.selected_coin.no;
            const title = this.addr_title;
			const address = this.address;
            this.save = true;
			this.$http.post(`${api}/member/wallet/transfer/address/add`,{token, title, address}).then(
			res => {
				if(res.status==200){
                    if(res.data.code == '200'){
                        this.$alert(this.$t('mywallet.11'));
                        this.addr_title = "";
                        this.address = "";
                        this.selected_coin = {};
                        this.withdrawAddress();
                    }				
				}
			}).finally(() => {
                this.save = false;
            })
        },        
        editClick(no, coin, net, symbol, title, address) {
            this.edit = true;
            this.edit_no = no;
            this.selected_coin = {no: coin, net, symbol};
            this.addr_title = title;
            this.address = address;
        },
        AddressEdit : function(){
            const no = this.edit_no;
            const token = this.selected_coin.no;
            const title = this.addr_title;
			const address = this.address;

            this.save = true;
            const api = this.$store.state.baseURL;
			this.$http.post(`${api}/member/wallet/transfer/address/edit`,{no, token, title, address}).then(
			res => {
				if(res.status==200){
                    if(res.data.code == '200'){
                        this.$alert(this.$t('mywallet.26'));
                        this.addr_title = "";
                        this.address = "";
                        this.edit = false;
                        this.edit_no = '';
                        this.selected_coin = {};
                        this.withdrawAddress();
                    }		
				}
			}).finally(() => {
                this.save = false;
            })
        },
        AddressDel: function(no, title) {
            this.$confirm(title + " " + this.$t('mywallet.15')).then(() => {
                this.save = true;
                const api = this.$store.state.baseURL;
                this.$http.post(`${api}/member/wallet/transfer/address/del`,{no}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code == '200'){
                            this.$alert(this.$t('mywallet.16'));
                            this.withdrawAddress();
                        }else{
                            this.$alert(this.$t('mywallet.17'));
                        }						
                    }
                }).finally(() => {
                    this.save = false;
                })
            }).catch(() => {})
        },
        CopyWallet: function (addr) {
            this.$copyText(addr).then( 
            () =>{
                this.$fire({title:this.$t('mywallet.18'),html : addr});
            })
        },    
        CancelQr: function() {
            this.turnCameraOff()
            this.camera_area = false;
        },
        runCamera: function(){
            this.turnCameraOn();
            this.camera_area = true;
        },       
        onDecode (result) {
            this.address = result;
            this.turnCameraOff()
            this.camera_area = false;
        }, 
        turnCameraOn () {
            this.camera = 'auto'
        },
        turnCameraOff () {
            this.camera = 'off'
        },    
    }
}
</script>
<style scoped>
.has_fixed_bottom_box .container{
    min-height: auto;
}
.history{
    min-height: calc(100vh - 280px);
    height: calc(100vh - 85px - 186px);
    overflow-y: auto;
}
.botC{
    background-color: #fff;
}

.history_box .form-group.line .form-control{
    border-bottom: none;
    font-size: 14px;
}
.history_box .form-group.line label{
    opacity: 0.6;
    color: #333;
}
.history_box .removeBtn{
    color: #aaa;
}
.history_box .removeBtn:first-child{
    border-right: 1px solid #ddd;
}

.form-control.border-line{
    border: 2px solid #4B67F9 ;
    background-color: #fff;
    border-radius: 6px;
}
.form-control.border-line:focus{
    border-bottom: 2px solid #4B67F9;
}

.qrcode-reader__overlay{
    width: 640px;
    height: 480px;
}

.botC{
    background-color: #fff;
    border-radius: 25px 25px 0 0;
}
.btn-primary{
    background: #4B67F9;
    border: none;
}


.addr_list li{
    /* background-color: #fbfcfd; */
    /* background-color:#f9f9f9; */
    /* border-radius: 4px; */
    /* padding: 14px; */
    border-bottom: 1px solid #eee;
}
.addr_list .addr_name{
    font-size: 1.1em;
    font-weight: 500;
}
.addr_list .addr_txt{
    /* font-size: 13px; */
    font-size: 0.9em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
input.border{
    padding: 10px;
    font-size: 14px;
    height: 44px;
    color: #bbb;
    background-color: #fff;
    border-radius: 5px;
}
input.border+.btn{
    bottom: 4px;
}
.qrcode_box{
    margin-bottom: 30px;
}
select.default{
    /* font-size: 14px; */
    font-size: 0.9em;
    color: #6c757d;
}
</style>