<template>
    <div id="InquiryForm" class="has_fixed_bottom_box">
        <div class="container">
            <div class="form_box">
                <div class="form-group line mb-0">
                    <input type="text" class="form-control" v-model="inquiryTitle" :placeholder="$t('inquiry.5')">
                </div>
                <div class="form-group line mb-0 image_add">
                    <label for="iqImage" class="mb-0 mr-2"><span class="btn btn-primary"><i class="fas fa-link"></i></span></label>
                    <input type="file" :src="inquiryImage" alt="" multiple @change="preview" accept="image/x-png,image/gif,image/jpeg,image/jpg" id="iqImage">
                    <small v-if="img_aws==0">{{$t('inquiry.20')}}</small>
                    <small v-else>{{img_aws.length}} {{$t('inquiry.21')}}</small>
                </div>
                <div class="form-group mb-0 p-3">
                    <!-- <img :src="img_alt" alt="img" v-if="img_alt"> -->
                    <ul class="imgs">
                        <li v-for="(item,index) in img_aws" :key="index" class="mb-2">
                            <img :src="item" alt="img" v-if="item" class="w-100">
                            <i class="fas fa-times remove_icon" @click="removeImg(index)"></i>
                        </li>
                    </ul>
                    <textarea :placeholder="$t('inquiry.6')" v-model="memo" class="p-0" maxlength="10000"></textarea>
                </div>
            </div>
            <div class="fixed_bottom_box p-3">
                <button class="btn btn-block btn-primary" @click="inquiryAdd" v-if="!edit"><small>{{$t('inquiry.7')}}</small></button>
                <button class="btn btn-block btn-primary" @click="inquiryEdit" v-else><small>{{$t('inquiry.8')}}</small></button>
            </div>
        </div>
    </div>
</template>

<script>
import AWS from 'aws-sdk';

export default {
    data(){
        return{
            AWSURL: this.$store.state.AWS.URL,
            albumBucketName : this.$store.state.AWS.albumBucketName,
            bucketRegion : this.$store.state.AWS.bucketRegion,
            IdentityPoolId : this.$store.state.AWS.IdentityPoolId,

            title: this.$t("menu.2"),
            prevP: false,
            pageD: false,
            index: 0,
            edit:false,
            inquiryTitle: '',
            memo: '',
            inquiryImage:'',
            img_aws: []
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)        
        
        const login = this.$store.state.login;
        if(login){
            this.Token_Check();
            if(this.$route.params.index !== undefined){
                this.edit = true;
                this.index = this.$route.params.index;
                this.inquiryEditData();
            }
            else{
                this.edit = false;
            }
        }
    },
    methods: {   
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                    const code = res.data.code;
                    if(code == '9999'){
                        this.$alert(this.$t('signout.1')).then(()=>{
                            this.$store.dispatch('SETLOGOUT').then(()=>{
                                this.$router.push({name:'Signin'});
                            });
                        })
                    }
                }
            })
        },
        preview:function(e){
            const file = e.target.files;
            file.forEach(i => {             
                this.fileUpload(i);
            });
        },
        fileUpload:function(file){
            const files = file;
            const date = new Date();
            const timestamp = date.getTime();
            const fileName = timestamp + files.name;
            AWS.config.update({region: this.bucketRegion,credentials: new AWS.CognitoIdentityCredentials({IdentityPoolId: this.IdentityPoolId})});
            var s3 = new AWS.S3({apiVersion: "2006-03-01",params: { Bucket: this.albumBucketName + '/inquiry'}});
            s3.upload({Key: fileName, Body : files, ACL:'public-read','ContentType': files.type}, (err)=>{
                if(err == null){
                    this.img_aws.push(this.AWSURL + 'inquiry/' + fileName);
                }
            })
        },
        removeImg:function(index){
            this.img_aws.splice(index, 1);
        },
        inquiryAdd:function() {
            const title = this.inquiryTitle;
            const memo = this.memo;

            if(title == ""){
                this.$alert(this.$t('inquiry.9'));
                return;
            }
            if(memo == ""){
                this.$alert(this.$t('inquiry.12'));
                return;
            }

            const api = this.$store.state.baseURL;
            const img = this.img_aws.join(',');
            
            this.$http.post(`${api}/member/inquiry/add`,{title, memo, img}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.$alert(this.$t('inquiry.10')).then(()=>{
                                this.$router.push('/'+this.$i18n.locale+'/page/inquiry-list');
                            })
                        }else{
                            this.$alert(this.$t('inquiry.11'));
                        }
                    }
                }
            )
        },
        inquiryEditData:function(){
            const no = this.index;
            const api = this.$store.state.baseURL;
            
            this.$http.post(`${api}/member/inquiry/detail`,{no}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.inquiryTitle = res.data.info.title;
                            this.memo = res.data.info.memo;
                            if(res.data.info.img != null && res.data.info.img != '') this.img_aws = res.data.info.img.split(',');
                        }
                    }
                }
            )
        },
        inquiryEdit:function() {
            const no = this.index;
            const title = this.inquiryTitle;
            const memo = this.memo;

            if(title == ""){
                this.$alert(this.$t('inquiry.9'));
                return;
            }
            if(memo == ""){
                this.$alert(this.$t('inquiry.12'));
                return;
            }

            const api = this.$store.state.baseURL;
            const img = this.img_aws.join(',');
            
            this.$http.post(`${api}/member/inquiry/edit`,{no, title, memo, img}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.$alert(this.$t('inquiry.13')).then(()=>{
                                this.$router.push('/'+this.$i18n.locale+'/page/inquiry-list');
                            })
                        }
                        else{
                            this.$alert(this.$t('inquiry.14'));
                        }
                    }
                }
            )
        }
    },
}
</script>
<style scoped>
    .container{
        padding-top: 0;
        padding-bottom: 0;
    }
    .form_box{
        width: calc(100% + 30px);
        transform: translateX(-15px);
    }
    .form-group input{
        border: none;
        border-bottom: 1px solid #eaeaea;
        background-color: #fff;
        padding: 5px 15px;
        height: 46px;
    }
    .form-group.line .form-control:focus{
        border-bottom: 1px solid #eaeaea;
    }   
    .form-group textarea{
        border: none;
        resize: none;
        width: 100%;
        height: calc(100vh - 150px);
        display: block;
        font-size: 14px;
        box-sizing: border-box;
    }
    .form-group textarea::placeholder{
        color: #aaa;
    }
    .form-group textarea:focus{
        outline: none;
    }
    .image_add{
        padding: 0.5em .8em;
        border-bottom: 1px solid #eee;
        display: flex;
        align-items: center;
    }
    .image_add small{
        color: #aaa;
    }
    input[type="file"] { /* 파일 필드 숨기기 */ 
        position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip:rect(0,0,0,0); border: 0; 
    }
    input.basic{
        border: none;
        width: auto;
        margin: 0;
        padding: 0;
        height: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .imgs li{
        position: relative;
    }
    .imgs .remove_icon{
        color: #fff;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 100%;
        position: absolute;
        top: 6px;
        right: 6px;
        padding: 4px;
        width: 20px;
        height: 20px;
        text-align: center;
        font-size: 11px;
    }
</style>
