<template>
    <div id="mnemonic" class="has_fixed_bottom_box">
        <div class="container">
            <div class="step01" v-if="step == 1">
                <div class="top_txt_box mb-5 mt-4">
                    <h3><div class="">니모닉코드로 지갑 복구</div></h3>
                    <p style="font-size:0.85em">지갑을 복구하려면 비밀 구문을 여기에 입력하세요.</p>

                    <div style="font-size:0.8em; font-weight:normal; color: #de3232;">
                        <p style="margin:0;"><i class="fas fa-exclamation-triangle"></i></p>
                        <p class="mt-0 mb-1">백업한 니모닉코드 전체를 순서대로 입력해주세요.</p>
                        <p class="mt-0 mb-1">철자와 띄어쓰기에 유의하여 정확하게 입력해주세요.</p>
                        <p class="mt-0 mb-1">지갑의 보안을 위해 타 지갑의 니모닉코드를 복원하지 마세요.</p>
                        <p class="mt-0">서비스 이용에 제한이 있을 수 있습니다.</p>
                    </div>
                </div>
                <div class="form_box">
                    <div class="form-group line">
                        <label>지갑 비밀복구 구문</label>
                        <textarea type="text" class="form-control border-line mt-3 p-3" style="height: 250px;" v-model="mnemonic"></textarea>
                    </div>
                </div>
                <div class="fixed_bottom_box p-3"><button type="button" class="btn btn-block btn-primary" @click="mnemonic_chk"><small>확인</small></button></div>
            </div>
            <div class="step02" v-if="step == 2">
                <div class="form_box">
                    <div class="form-group line">
                        <label>새 암호(8자 이상)</label>
                        <input type="password" class="form-control">
                    </div>
                </div>
                <div class="form_box">
                    <div class="form-group line">
                        <label>암호 확인</label>
                        <input type="password" class="form-control">
                    </div>
                </div>
                <div class="fixed_bottom_box p-3"><button type="button" class="btn btn-block btn-primary"><small>복구</small></button></div>
            </div>
            
            <div v-if="error">
                <div class="popup_box on">
                    <div class="popup">
                        <div class="dimm" @click="error = false"></div>
                        <div class="content_box">
                            <div class="content text-center p-3">
                                <p class="small">
                                비밀 구문이 틀렸습니다.<br>다시 올바르게 입력해주십시오.
                                </p>
                                <button @click="error = false" class="btn text-primary btn-sm small">확인</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data(){
        return{
            step:1,
            title: '지갑 복구',
            mnemonic:'',
            lang: this.$route.params.lang,
            code:[],
            error:false,
        }
    },
    
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
        
        for(var i=0; i<3; i++){
            let randomNum = null;
            randomNum = Math.floor(Math.random()*12+1)
            if(this.code.indexOf(randomNum) === -1){
                this.code.push(randomNum)
            }else{
                i--
            }
        }
    },
    methods:{
        mnemonic_chk(){
            if(this.mnemonic==''){
                this.error = true;
            }else{
                this.step=2;
            }
        }
    }
}
</script>
<style scoped>
.popup .content,.popup .content_box{
    height: auto !important;
    margin-top: 0;
}
.popup .content{
    border-radius: 7px;
}
</style>