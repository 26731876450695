<template>
    <div id="InquiryDetail">
        <div class="container">
            <div class="board_tit_box underline mb-4">
                <p class="tit">{{info.title}}</p>
                <p class="date">{{info.date}}</p>
            </div>
            <ul class="imgs">
                <li v-for="(item,index) in img_aws" :key="index" class="mb-2">
                    <img :src="item" alt="img" v-if="item" class="w-100">
                    <!-- <i class="fas fa-times remove_icon"></i> -->
                </li>
            </ul>
            <pre class="board_content_box">
                {{info.memo}}
            </pre>
            <div class="text-right" v-if="info.answer == 0">
                <router-link class="btn btn-primary btn-sm" :to="`/${$i18n.locale}/page/inquiry-form/${info.no}`">{{$t('inquiry.15')}}</router-link>
                <a class="btn btn-danger btn-sm ml-1" @click="delInquiry">{{$t('inquiry.16')}}</a>
            </div>

            <div class="board_answer_box mt-5" v-if="info.answer == 1">
                <div class="mb-4">
                    <span class="badge badge-primary mr-1 font-weight-normal">{{$t('inquiry.1')}}</span>
                    <span class="date">{{info.answerDate}}</span>
                </div>
                <pre class="board_content_box mb-0">
                    {{info.answer_memo}}
                </pre>
                <ul class="imgs">
                    <li v-for="(item,index) in img_answer" :key="index" class="mb-2">
                        <img :src="item" alt="img" v-if="item" class="w-100">
                        <!-- <i class="fas fa-times remove_icon"></i> -->
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    data(){
        return{
            title: this.$t("menu.2"),
            index: this.$route.params.index,
            prevP: false,
            pageD: false,
            info: {},
            img_aws:[],
            img_answer: []
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
                
        const login = this.$store.state.login;
        if(login){
            this.Token_Check();
        }
    },
    methods: {
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                    const code = res.data.code;
                    if(code == '200'){    
                        this.detailData();
                    }else if(code == '9999'){
                        this.$alert(this.$t('signout.1')).then(()=>{
                            this.$store.dispatch('SETLOGOUT').then(()=>{
                                this.$router.push({name:'Signin'});
                            });
                        })
                    }
                }
            })
        },
        detailData:function() {
            const no = this.index;
            const api = this.$store.state.baseURL;
            
            this.$http.post(`${api}/member/inquiry/detail`,{no}).then(
                res => {
                    if(res.status==200){
                        if(res.data.info === undefined) {
                            this.$router.push('/'+this.$i18n.locale+'/page/inquiry-list');
                            return;
                        }
                        if(res.data.code=='200'){
                            this.info = res.data.info;
                            if(res.data.info.img != null && res.data.info.img != '') this.img_aws = res.data.info.img.split(',');
                            if(res.data.info.answer_img != null && res.data.info.answer_img != '') this.img_answer = res.data.info.answer_img.split(',');
                        }
                    }
                }
            )
        },
        delInquiry: function() {
            this.$confirm(this.$t('inquiry.17')).then(() => {
                const no = this.index;
                const api = this.$store.state.baseURL;
                
                this.$http.post(`${api}/member/inquiry/del`,{no}).then(
                    res => {
                        if(res.status==200){
                            if(res.data.code=='200'){
                                this.$alert(this.$t('inquiry.18')).then(()=>{
                                    this.$router.push('/'+this.$i18n.locale+'/page/inquiry-list');
                                })
                            }
                            else{
                                this.$alert(this.$t('inquiry.19'));
                            }
                        }
                    }
                )
            }).catch(()=>{})
        }
    },
}
</script>
<style scoped>
.btn-sm{
    font-size: 12px;
}
    .badge{
        border-radius: 0 !important; 
    }
    .board_content_box{
        min-height: 50px;
        word-break: break-all;
    }
    .board_answer_box{
        border-top: 1px solid #eaeaea;
        padding-top: 15px;
    }
    .board_answer_box .date{
        color: #007bff;
        font-size: 14px;
    }
    .imgs li{
        position: relative;
    }
</style>