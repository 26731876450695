<template>
    <div id="ChgTransactionPw" class="has_fixed_bottom_box">
        <div class="container">
            <div class="step01" v-if="step == 1">
                <div class="top_txt_box mb-5 mt-4">
                    <h3><div class="">
                        {{$t('change.1')}}
                    </div></h3>
                    <p>
                        {{$t('change.2')}}<br>
                    </p>
                </div>
                <div class="form_box">
                    <div class="form-group line">
                        <label>{{$t('change.3')}}</label>
                        <input type="email" class="form-control" v-model="email" @keyup.enter="EmailCheck">
                    </div>
                </div>
                <div class="fixed_bottom_box p-3">
                    <button type="button" class="btn btn-block btn-primary " @click="EmailCheck"><small>{{$t('change.4')}}</small></button>
                </div>
            </div>
            <div class="step02" v-if="step == 2">
                <div class="top_txt_box mb-5 mt-4">
                    <h3><div class="">
                        {{$t('change.5')}}
                    </div></h3>
                    <p>
                        {{$t('change.6')}}<br>
                        {{$t('change.7')}}
                    </p>
                </div>
                <div class="form_box">
                    <div class="form-group line">
                        <label>{{$t('change.8')}}</label>
                        <input type="text" class="form-control" v-model="code" :placeholder="$t('change.8')" required>
                    </div>
                </div>
                <div class="fixed_bottom_box p-3">
                    <button type="button" class="btn btn-block btn-primary " @click="ConfirmEmailCode"><small>{{$t('change.9')}}</small></button>
                </div>
            </div>
            <div class="step02" v-if="step == 3">
                <div class="top_txt_box mb-5 mt-4">
                    <h3><div class="">{{$t('change.38')}} Private Key</div></h3>
                    <p> {{$t('change.35')}}</p>
                </div>
                <div class="form_box">
                    <div class="form-group line">
                        <div class="empty_box" style="margin-top: 70px;">
                            <i class="fal fa-check-circle"></i>
                            <p class="tit">
                                <label class="text-center">{{$t('change.36')}}</label>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="fixed_bottom_box p-3">
                    <router-link :to="`/${$i18n.locale}/page/menu`" type="button" class="btn btn-block btn-primary"><small>{{$t('change.37')}}</small></router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            title: this.$t('change.38') + " Private Key",
            prevP:true,
            pageD: false,
            email: "",
            step: 1,
            code: '',
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD);
        this.Token_Check();

    },
    methods: {    
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                    const code = res.data.code;
                    if(code == '9999'){
                        this.$alert(this.$t('signout.1')).then(()=>{
                            this.$store.dispatch('SETLOGOUT').then(()=>{
                                this.$router.push({name:'Signin'});
                            });
                        })
                    }
                }
            })
        },  
        EmailCheck: function() {
            const api = this.$store.state.baseURL;
            
            const email = this.email;
            if(email == ""){
                this.$alert(this.$t('change.16'));
                return;
            }

            var check = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(email);
            if(!check){
                this.$alert(this.$t('change.17'));
                return;
            }

            this.$http.post(`${api}/member/setting/passwordChg/email_check`,{email}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.EmailCodeSend();
                        }
                        else{
                            this.$alert(this.$t('change.18'));
                            return;
                        }
                    }
                }
            )
        },  
        EmailCodeSend: function() {
            const api = this.$store.state.baseURL;
            
            this.$http.post(`${api}/member/common/email_security`,{}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.step = 2;
                        }
                    }
                }
            )
        },
        ConfirmEmailCode:function(){
            const api = this.$store.state.baseURL;
            const code = this.code;

            if(code == ""){
                this.$alert(this.$t('change.19'));
                return false;
            }

            this.$http.post(`${api}/member/common/email_code_check`,{code}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.PrivateKeySend();
                        }
                        else {
                            this.$alert(this.$t('change.20'));
                            return;
                        }
                    }
                }
            )
        },
        PrivateKeySend:function() {
            const api = this.$store.state.baseURL;            

            this.$http.post(`${api}/member/setting/send/privatekey`,{}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.step = 3;
                        }
                    }
                }
            )
        }
    },
}
</script>