<template>
    <div id="ChgLoginPw" class="has_fixed_bottom_box">
        <div class="container">
            <div class="step01" v-if="step == 1">
                <div class="top_txt_box mb-5 mt-4">
                    <h3><div class="">
                        {{$t("change.1")}}
                    </div></h3>
                    <p>
                        {{$t("change.2")}}<br>
                    </p>
                </div>
                <div class="form_box">
                    <div class="form-group line">
                        <label>{{$t("change.3")}}</label>
                        <input type="email" class="form-control" v-model="email" @keyup.enter="EmailCheck">
                    </div>
                </div>
                <div class="fixed_bottom_box p-3">
                    <button type="button" class="btn btn-block btn-primary " @click="EmailCheck"><small>{{$t("change.4")}}</small></button>
                </div>
            </div>
            <div class="step02" v-if="step == 2">
                <div class="top_txt_box mb-5 mt-4">
                    <h3><div class="">
                        {{$t("change.5")}}
                    </div></h3>
                    <p>
                        {{$t("change.6")}}<br>
                        {{$t("change.7")}}
                    </p>
                </div>
                <div class="form_box">
                    <div class="form-group line">
                        <label>{{$t("change.8")}}</label>
                        <input type="text" class="form-control" v-model="code" :placeholder="$t('change.8')" required>
                    </div>
                </div>
                <div class="fixed_bottom_box p-3">
                    <button type="button" class="btn btn-block btn-primary " @click="ConfirmEmailCode"><small>{{$t('change.9')}}</small></button>
                </div>
            </div>
            <div class="step02" v-if="step == 3">
                <div class="top_txt_box mb-5 mt-4">
                    <h3><div class="">
                        {{$t('change.10')}}
                    </div></h3>
                    <p>
                        {{$t('change.11')}}
                    </p>
                </div>
                <div class="form_box">
                    <div class="form-group line">
                        <label>{{$t('change.12')}}</label>
                        <input type="password" class="form-control" v-model="oldPassword" :placeholder="$t('signup.3')" required>
                    </div>
                    <div class="form-group line">
                        <label>{{$t('change.13')}}</label>
                        <input type="password" class="form-control" v-model="password" @focusout="CheckPassword()" :placeholder="$t('signup.3')" required>
                    </div>
                    <div class="form-group line">
                        <label>{{$t('change.13')}} {{$t('change.14')}}</label>
                        <input type="password" class="form-control" v-model="passwordConf" @focusout="CheckPasswordConfirm()" :placeholder="$t('signup.3')" required>
                    </div>
                </div>
                <div class="fixed_bottom_box p-3">
                    <button type="button" class="btn btn-block btn-primary" @click="PasswordChange"><small>{{$t('change.15')}}</small></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            title: this.$t("menu.4"),
            prevP:true,
            pageD: false,
            email: '',
            code: '',
            oldPassword: '',
            password: '',
            passwordConf: '',
            passwordErr: false,
            step: 1,
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
        this.Token_Check();
    },
    methods: {
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                    const code = res.data.code;
                    if(code == '9999'){
                        this.$alert(this.$t('signout.1')).then(()=>{
                            this.$store.dispatch('SETLOGOUT').then(()=>{
                                this.$router.push({name:'Signin'});
                            });
                        })
                    }
                }
            })
        },  
        EmailCheck: function() {
            const api = this.$store.state.baseURL;
            
            const email = this.email;
            if(email == ""){
                this.$alert(this.$t('change.16'));
                return;
            }

            var check = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(email);
            if(!check){
                this.$alert(this.$t('change.17'));
                return;
            }

            this.$http.post(`${api}/member/setting/passwordChg/email_check`,{email}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.EmailCodeSend();
                        }
                        else{
                            this.$alert(this.$t('change.18'));
                            return;
                        }
                    }
                }
            )
        },
        EmailCodeSend: function() {
            const api = this.$store.state.baseURL;
            
            this.$http.post(`${api}/member/common/email_security`,{}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.step = 2;
                        }
                    }
                }
            )
        },
        ConfirmEmailCode:function(){
            const api = this.$store.state.baseURL;
            const code = this.code;

            if(code == ""){
                this.$alert(this.$t('change.19'));
                return false;
            }

            this.$http.post(`${api}/member/common/email_code_check`,{code}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.step = 3;
                        }
                        else {
                            this.$alert(this.$t('change.20'));
                            return;
                        }
                    }
                }
            )
        },
        CheckPassword : function() {            
            var character = this.password;
            if(character == ""){
                this.passwordErr = true;
                this.$alert(this.$t('change.21'));
            }
            else {
                var check = /^(?=.*\d{1,50})(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*[~`!@#$%^&*()-+=]).{10,50}$/.test(character);
                if(!check){
                    this.passwordErr = true;
                    this.$alert(this.$t('change.21'));
                }
                else{                    
                    this.passwordErr = false;
                }
            }
        },
        CheckPasswordConfirm: function() {
            if(this.password !== this.passwordConf){
                this.$fire({html:this.$t('change.22')});
            }
        },
        PasswordChange: function() {
            if(this.oldPassword == ''){
                this.$alert(this.$t('change.23'));
                return false;
            }

            if(this.passwordErr){
                this.$alert(this.$t('change.21'));
                return false;
            }

            if(this.password !== this.passwordConf){
                this.$fire({html:this.$t('change.22')});
                return;                
            }

            const api = this.$store.state.baseURL;
            const new_password = this.password;
            const old_password = this.oldPassword;
            
            this.$http.post(`${api}/member/setting/password_change`,{old_password,new_password}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.$alert(this.$t('change.24')).then(()=>{
                                this.$store.dispatch('SETLOGOUT').then(()=>{
                                    this.$router.push({name:'Signin'});
                                });
                            })
                        }
                        else if(res.data.code=='100'){
                            this.$alert(this.$t('change.25'));
                            this.oldPassword = "";
                        }
                        else if(res.data.code=='300'){
                            this.$alert(this.$t('change.26'));
                        }
                        else if(res.data.code=='400'){
                            this.$alert(this.$t('change.27'));
                        }
                    }
                }
            )
        }
    },
}
</script>
